<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="modelTable.content"
      :search="search"
      fixed-header
      height="auto"
      :options.sync="options"
      :server-items-length="modelTable.totalElements"
      :page.sync="page"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row justify-end p-2 pb-0">
          <div class="col-sm-12 pb-0 col-md-6 col-lg-3 col-xl-3">
            <AllBranchOfficeComponentClear
              ref="branchOfficeDestination"
              v-on:branchChange="branchChange"
              branchLabel="Sucursal origen"
              :branchOfficeList="branchOfficeList"
              :isDisabled="isDisabled"
            />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-autocomplete
              class="br"
              v-model="userFilter"
              :items="listUserFilter"
              @change="userFilterChange(true)"
              :label="
                !pagesAtributes.sortFilters.branchOfficeSelectOriginFilter
                  ? 'Elige una sucursal origen'
                  : 'Generado por'
              "
              data-vv-name="select"
              clearable
              :disabled="
                !pagesAtributes.sortFilters.branchOfficeSelectOriginFilter
              "
            ></v-autocomplete>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-select
              class="br"
              @change="statusFilterChange()"
              v-model="statusFilter"
              :items="
                medicalPrecription ? statusFilterMedicalList : statusFilterList
              "
              clearable
              label="Estatus"
              data-vv-name="select"
            ></v-select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-text-field
              v-model="search"
              v-on:keyup.enter="searchValue"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              :disabled="loading_search"
              :label="$t('table.search')"
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  ></i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div v-if="!medicalPrecription">
          <div
            v-if="
              (type == 1 && shipmentsCancel) ||
              (type == 0 && shipmentsCancelDispensing)
            "
          >
            <v-tooltip
              top
              v-if="item.statusShipment != 7 && item.statusShipment != 6"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="hand"
                  @click="cancellation(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i class="fa fa-ban text-danger ml-5"></i>
                </span>
              </template>
              <span>
                {{ $t("table.cancel") }}
              </span>
            </v-tooltip>

            <span v-if="item.statusShipment == 7 || item.statusShipment == 6">
              <i class="fa fa-ban ml-5" style="color: #e2e2e2"></i>
            </span>

            <v-icon
              small
              class="mr-2"
              color="#E2E2E2"
              v-if="item.statusShipment == 7 || item.statusShipment == 6"
            >
              mdi mdi-ban
            </v-icon>
          </div>
        </div>
        <div v-else>
          <div
            v-if="
              (type == 1 && shipmentsCancel) ||
              (type == 0 && shipmentsCancelDispensing)
            "
          >
            <v-tooltip
              top
              v-if="
                item.statusPrescription != 7 && item.statusPrescription != 3
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="hand"
                  @click="cancellation(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i class="fa fa-ban text-danger ml-5"></i>
                </span>
              </template>
              <span>
                {{ $t("table.cancel") }}
              </span>
            </v-tooltip>

            <span
              v-if="
                item.statusPrescription == 7 || item.statusPrescription == 3
              "
            >
              <i class="fa fa-ban ml-5" style="color: #e2e2e2"></i>
            </span>

            <v-icon
              small
              class="mr-2"
              color="#E2E2E2"
              v-if="
                item.statusPrescription == 7 || item.statusPrescription == 3
              "
            >
              mdi mdi-ban
            </v-icon>
          </div>
        </div>
      </template>

      <template v-slot:[`item.statusShipment`]="{ item }">
        <ChipTableCommonPagination
          :status="item.statusShipment"
          :medicalPrecription="medicalPrecription"
        />
      </template>

      <template v-slot:[`item.statusPrescription`]="{ item }">
        <ChipTableCommonPagination
          :status="item.statusPrescription"
          :medicalPrecription="medicalPrecription"
        />
      </template>

      <template v-slot:[`item.prescriptionType`]="{ item }">
        {{ prescriptionType(item.prescriptionType) }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip v-if="item.status == 1" color="green" dark class="m-1">
          Activo
        </v-chip>
        <v-chip v-if="item.status == 3" color="grey" dark class="m-1">
          Producto no conforme
        </v-chip>
        <v-chip v-if="item.status == 4" color="red" dark class="m-1">
          Cancelado
        </v-chip>
      </template>
    </v-data-table>
    <ButtonPrintComponent />
  </div>
</template>

<script>
import ChipTableCommonPagination from "@/common/table/ChipTableCommonPagination";
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import AllBranchOfficeComponentClear from "@/common/select/AllBranchOfficeComponentClear";
import { BranchOfficeRequest, OrderRequest } from "@/core/request";

export default {
  props: {
    headers: [],
    type: null,
    shipmentsCancel: false,
    shipmentsCancelDispensing: false,
    medicalPrecription: false,
    pagesAtributes: Object,
    changeTable: Function,
    modelTable: Object,
  },
  data() {
    return {
      search: "",
      dataList: [],
      options: {},
      page: 1,
      header: this.headers,
      statusName: "",
      loading_search: false,
      statusFilter: null,
      userFilter: "",
      branchOfficeList: [],
      branchOriginFilter: "",
      statusFilterList: [
        {
          value: 1,
          text: "Pendiente de Finalizar",
        },
        {
          value: 2,
          text: "Picking",
        },
        {
          value: 3,
          text: "Validación de Picking",
        },
        {
          value: 4,
          text: "Pendiente de Envío",
        },
        {
          value: 5,
          text: "Tránsito",
        },
        {
          value: 6,
          text: "Recepción",
        },
        {
          value: 7,
          text: "Cancelado",
        },
      ],
      statusFilterMedicalList: [
        {
          value: 1,
          text: "Pendientes",
        },
        {
          value: 2,
          text: "Dispensado",
        },
        {
          value: 3,
          text: "Cancelado",
        },
      ],
      isDisabled: false,
      listUserFilter: [],
    };
  },
  components: {
    ButtonPrintComponent,
    ChipTableCommonPagination,
    AllBranchOfficeComponentClear,
  },
  async mounted() {
    await this.getListBranchOffice(this.type == 0 ? 2 : 1);
    await this.listAllUserFilter(this.type == 0 ? 2 : 1);
  },
  watch: {
    async search() {
      if (this.search == "") {
        await this.searchValue();
      }
    },
    options: {
      async handler() {
        await this.pagination();
      },
      deep: true,
    },
  },
  methods: {
    /**
     * Table pagination. / Paginado de la tabla.
     * @method
     */
    async pagination() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.pagesAtributes.sortBy = sortBy[0];
      this.pagesAtributes.sortDesc = sortDesc[0];
      this.pagesAtributes.currentPage = page - 1;
      this.pagesAtributes.itemsPerPage = itemsPerPage;
      await this.changeTable();
    },
    /**
     * Search input. / Input de búsqueda.
     * @method
     */
    async statusFilterChange() {
      if (this.statusFilter) {
        this.pagesAtributes.sortFilters.statusSelectFilter = this.statusFilter;
      } else {
        delete this.pagesAtributes.sortFilters.statusSelectFilter;
      }
      await this.performSearch(this.pagesAtributes);
    },
    async searchValue() {
      try {
        this.loading_search = true;
        this.pagesAtributes.search = this.search;
        await this.performSearch(this.pagesAtributes);
        this.page = 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_search = false;
      }
    },
    async performSearch(pagesAtributes) {
      await this.changeTable(pagesAtributes);
    },
    reloadComponentHeader(header) {
      this.header = header;
    },
    async userFilterChange(payload) {
      if (this.userFilter && payload) {
        this.pagesAtributes.sortFilters.userSelectFilter = parseInt(
          this.userFilter
        );
      } else {
        if (!this.userFilter)
          delete this.pagesAtributes.sortFilters.userSelectFilter;
      }
      if (payload) {
        this.isDisabled = true;
        //await this.getListBranchOffice(this.type == 0 ? 2 : 1, this.userFilter);
        this.isDisabled = false;
        await this.performSearch(this.pagesAtributes);
      }
    },
    async branchChange(item) {
      this.userFilter = null;
      this.userFilterChange(false);
      if (item) {
        this.pagesAtributes.sortFilters.branchOfficeSelectOriginFilter = item;
      } else {
        delete this.pagesAtributes.sortFilters.branchOfficeSelectOriginFilter;
        await this.getListBranchOffice(this.type == 0 ? 2 : 1);
      }
      if (!this.userFilter)
        await this.listAllUserFilter(this.type == 0 ? 2 : 1, item);
      await this.performSearch(this.pagesAtributes);
    },
    reloadComponentSetList(dataList, header) {
      this.dataList.content = dataList;
      this.header = header;
    },
    reloadComponentClean() {
      this.dataList = [];
    },
    cancellation(item) {
      this.$emit("cancellation", item);
    },
    itemRowBackground: function (item, index) {
      return item.status == 7 ? "style-2" : "";
    },
    prescriptionType(id) {
      const types = {
        1: "Recetas",
        2: "Intrahospitalaria",
        3: "SRS",
      };
      return types[id] || "No definido";
    },
    async getListBranchOffice(orderType, idUser) {
      try {
        this.loading = this.$UtilFront.getLoadding();
        this.isDisabled = true;
        const payload = {
          orderType,
          ...(idUser && { idUser }),
        };
        const response = await BranchOfficeRequest.listBranchOfficeUsers(
          payload
        );
        this.branchOfficeList = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isDisabled = false;
        this.loading.hide();
      }
    },
    async listAllUserFilter(orderType, idBranchOffice) {
      const payload = {
        orderType,
        ...(idBranchOffice && { idBranchOffice }),
      };
      this.loading = this.$UtilFront.getLoadding();
      await OrderRequest.getAllUserList(payload)
        .then((response) => {
          this.listUserFilter = response.data;
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
  },
};
</script>

<style scoped>
.style-2 {
  background-color: rgb(254, 187, 187);
}
</style>
