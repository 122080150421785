<template>
  <div id="containerbar">
    <div class="rightbar">
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-9 col-lg-9">
            <h4 class="page-title">
              {{ $t("cancellation.supplies") }}
            </h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("cancellation.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title">
                      <i class="feather icon-slash mr-2"></i
                      >{{ $t(titleAction) }}
                    </h5>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <v-select
                      class="br"
                      outlined
                      dense
                      v-model="type"
                      :items="orderHistoryList"
                      :label="$t('label.type_starting_order')"
                      data-vv-name="select"
                    ></v-select>
                  </div>
                </div>
              </div>
              <CancellationTableComponent
                v-if="showTable"
                ref="tableCancellation"
                :headers="headers"
                v-on:cancellation="cancellation"
                :type="type"
                :shipmentsCancel="
                  withPermissionName(permissions.CAN_ORD_CAN_02)
                "
                :shipmentsCancelDispensing="
                  withPermissionName(permissions.CAN_ORD_DIS_CAN_02)
                "
                :pagesAtributes="pagesAtributes"
                :changeTable="changeTable"
                :medicalPrecription="medicalPrecription"
                :modelTable="modelTable"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="dialogCancellation"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="blue-fmedical">
          <v-toolbar-title class="pt-4">
            <p class="text-center">
              <i class="feather icon-alert-octagon mr-1"></i>
              {{ $t("cancellation.cancellation") }}
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="cleanModal()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="container">
          <div class="row">
            <AlertMessageComponent ref="alertMessageComponentModal" />
            <div class="col-md-12 pb-0">
              <p>
                <i class="fa fa-folder-open-o mr-2 text-fmedical-blue"></i>
                <strong>
                  <span v-if="type == 1 || type == 2">
                    {{ $t("cancellation.title") }}
                  </span>
                  <span v-if="type == 0">
                    {{ $t("cancellation.title_") }}
                  </span>
                </strong>
              </p>
            </div>
            <div class="col-md-12 pb-3 pt-0">
              <CancellationDetailShipmentComponent
                ref="detailShipment"
                v-if="type == 1 || type == 2"
              />
            </div>
            <div class="col-md-12 pb-0">
              <CancellationDetailPrescriptionComponent
                ref="detailPrescription"
                v-if="type == 0"
              />
            </div>
            <div class="col-md-12 pb-0">
              <p>
                <i class="fa fa-question-circle mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> Motivo de cancelación: </span>
                </strong>
              </p>
            </div>
            <div class="col-md-12 pb-0 pt-0">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="cancellationOrder">
                  <div class="row">
                    <div class="col-md-12 pb-0">
                      <validation-provider
                        v-slot="{ errors }"
                        name="motive"
                        rules="required|max:500"
                      >
                        <v-textarea
                          outlined
                          v-model="reason"
                          :counter="500"
                          :maxLength="500"
                          :error-messages="errors"
                          :label="$t('label.reason_cancellation')"
                          :disabled="sending"
                          oninput="this.value = this.value.toUpperCase()"
                          required
                        />
                      </validation-provider>
                    </div>
                    <div class="col-md-12 text-right">
                      <button
                        class="btn btn-outline-success hidden-print"
                        type="submit"
                        :disabled="sending || invalid"
                      >
                        <i class="fa fa-ban mr-1"></i>
                        {{ $t("global.cancel_order") }}
                      </button>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
            <div class="col-md-12">
              <CancellationTableComponentProducts
                ref="tableShipmentOrder"
                :headers="orderHeaders"
                :pagesAtributes="pagesAtributes"
                :changeTable="changeTable"
              />
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Vuex
import { mapState } from "vuex";
import {
  ShipmentsRequest,
  OrderRequest,
  OrderStockRequest,
  MedicalPrescriptionRequest,
} from "@/core/request";
import SelectBranchOffice from "@/common/select/SelectBranchOffice";
import CancellationTableComponent from "@/components/cancellation/CancellationTableComponent";
import CancellationTableComponentProducts from "@/components/cancellation/CancellationTableComponentProducts";

import CancellationDetailShipmentComponent from "@/components/cancellation/CancellationDetailShipmentComponent";
import CancellationDetailPrescriptionComponent from "@/components/cancellation/CancellationDetailPrescriptionComponent";
import {
  PICKING_ORDER,
  SHIPMENTS_CANCELLATION,
  DISPENSING_CANCELLATION,
} from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      title: "cancellation.cancellations",
      permissions: this.$PermissionConstants,
      /* Cabeceras */
      headers: [],
      shipmentsCancellationsHeader: SHIPMENTS_CANCELLATION(),
      dispensingCancellationsHeader: DISPENSING_CANCELLATION(),
      orderHeaders: PICKING_ORDER(),
      showTable: false,
      /* General */
      idUser: "",
      originId: "",
      type: 0,
      currentItem: {
        branchOfficeDestination: {},
        userBranchOffice: {
          user: {},
        },
      },
      dialogCancellation: false,
      /* Carga  */
      sending: false,
      loading: null,
      reason: "",
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
        sortFilters: {},
      },
      modelTable: {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      medicalPrecription: false,
    };
  },
  computed: {
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    titleAction() {
      if (this.type == 1) {
        return "cancellation.departures_history";
      } else if (this.type == 0) {
        return "cancellation.dispensing_history";
      } else if (this.type == 3) {
        return "cancellation.shrinkage_history";
      } else if (this.type == 4) {
        return "cancellation.history_returns";
      }
      return "Listado";
    },
  },
  methods: {
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    alertModal(type, message) {
      this.$refs.alertMessageComponentModal.reloadComponent(type, message);
    },
    withPermissionName(namePermission) {
      return this.$UtilFront.withPermissionName(namePermission);
    },
    clearModel() {
      this.modelTable = {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      };
    },
    async changeTable() {
      if (!this.dialogCancellation) {
        this.clearModel();
        if (this.type == 0) {
          await this.listAllMedicalPrescriptionByStatus(this.pagesAtributes);
        } else {
          await this.listAllShipmentsByTypeAndStatus(this.pagesAtributes);
        }
      }
    },
    async listAllShipmentsByTypeAndStatus(pagesAtributes) {
      //Mustro la pantalla de cargando
      this.loading = this.$UtilFront.getLoadding();
      await ShipmentsRequest.listAllShipmentsWithTypeAndStatus(pagesAtributes)
        .then((response) => {
          let data = response.data;
          this.modelTable = data;
          this.medicalPrecription = false;
          this.headers = SHIPMENTS_CANCELLATION();
        })
        .catch((error) => {
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async listAllMedicalPrescriptionByStatus(pagesAtributes) {
      try {
        this.loading = this.$UtilFront.getLoadding();
        const response =
          await MedicalPrescriptionRequest.findAllPrescriptionByBranchOfficeCancelations(
            pagesAtributes
          );
        let data = response.data;
        this.modelTable = data;
        this.medicalPrecription = true;
        this.headers = DISPENSING_CANCELLATION();
      } catch (error) {
        console.log(error);
        this.alert("error", error.message);
      } finally {
        this.loading.hide();
      }
    },
    cleanModal() {
      this.dialogCancellation = false;
      this.$refs.tableShipmentOrder.reloadComponentClean();
      this.reason = "";
      this.$refs.observer.reset();
    },

    /* Cambio el origen de la sucursal */
    updateOrigin(data) {
      this.originId = data;
      //this.changeTable();
    },
    cancellation(item) {
      //Abro el modal de cancelacion
      this.dialogCancellation = true;
      //Mustro la pantalla de cargando
      this.loading = this.$UtilFront.getLoadding();
      this.currentItem = item;
      OrderStockRequest.orderStockDetail({
        idOrder: item.orderId,
        status: [1, 3], // 1 y 3
      })
        .then((response) => {
          let { status, data } = response;
          if (status == 200) {
            this.$refs.tableShipmentOrder.reloadComponentSetList(
              data,
              PICKING_ORDER()
            );
          } else if (status == 204) {
            this.$refs.tableShipmentOrder.reloadComponentSetList(
              [],
              PICKING_ORDER()
            );
            this.alertModal("error", data);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alertModal("internal");
        })
        .finally(() => {
          //Almaceno el elemento actual
          if (this.type == 1 || this.type == 2) {
            this.$refs.detailShipment.reloadComponent(item);
          } else if (this.type == 0) {
            this.$refs.detailPrescription.reloadComponent(item);
          }
          //Elimino la pantalla de cargando
          this.loading.hide();
        });
    },
    cancellationOrder() {
      //Mustro la pantalla de cargando
      this.loading = this.$UtilFront.getLoadding();
      OrderRequest.orderRollBack({
        idOrder: this.currentItem.orderId,
        reason: this.reason,
        userBranchOffice: {
          idUser: this.idUser,
          //idBranchOffice: this.originId,
        },
      })
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.alert(
              "success",
              "ID Orden (" +
                this.currentItem.orderId +
                ") cancelada exitosamente."
            );
            // this.currentItem.status = 7;
            // this.currentItem.statusName = "Cancelado";
            this.cleanModal();
          } else {
            this.alertModal("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alertModal("internal");
        })
        .finally(() => {
          //Elimino la pantalla de cargando
          this.loading.hide();
          this.changeTable();
        });
    },
  },
  created() {
    //Almaceno el id del usuario
    this.orderHistoryList = [];
    this.idUser = this.$UtilFront.getDataUser().idUser;
    if (this.withPermissionName(this.permissions.CAN_ORD_LIST_01)) {
      this.orderHistoryList.push({
        text: "Salidas",
        value: 1,
      });
    }
    if (this.withPermissionName(this.permissions.CAN_ORD_DIS_LIST_01)) {
      this.orderHistoryList.push({
        text: "Dispensación",
        value: 0,
      });
    }
    this.type = this.orderHistoryList[0].value;
  },
  watch: {
    async type() {
      this.showTable = false;
      this.pagesAtributes = {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
        sortFilters: {},
      };
      this.$nextTick(() => {
        this.showTable = true;
      });
    },
    "$i18n.locale": function () {
      this.type == 0
        ? (this.headers = DISPENSING_CANCELLATION())
        : (this.headers = SHIPMENTS_CANCELLATION());
    },
  },
  components: {
    SelectBranchOffice,
    CancellationTableComponent,
    CancellationDetailShipmentComponent,
    CancellationDetailPrescriptionComponent,
    CancellationTableComponentProducts,
  },
};
</script>
