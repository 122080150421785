<template>
  <div class="row">
    <div class="col-md-12 pb-6 pt-0">
      <div class="card border-light">
        <div class="card-body">
          <div class="row">
            <div class="col-xs-12 col-md-4 pb-0">
              <p>
                <i class="fa fa-stethoscope mr-1 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("label.doctor_") }}: </span>
                </strong>
                {{ model.doctor.fullName }}
              </p>
            </div>
            <div class="col-xs-12 col-md-4 pb-0">
              <p>
                <i class="feather icon-user-plus mr-1 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("label.beneficiary") }}: </span>
                </strong>
                {{ model.fullName }}
              </p>
            </div>
            <div class="col-md-4">
              <p>
                <i class="fa fa-id-card-o mr-1 text-fmedical-blue"></i>
                <strong>
                  <span> CURP: </span>
                </strong>
                {{ model.curp }}
              </p>
            </div>
            <div class="col-md-4">
              <p>
                <i class="ti-truck mr-1 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("label.id_recipe") }}: </span>
                </strong>
                {{ model.idPrescription }}
              </p>
            </div>
            <div class="col-md-4">
              <p>
                <i class="feather icon-tag mr-1 text-fmedical-blue"></i>
                <strong>
                  <span> Clave de la receta: </span>
                </strong>
                {{ model.prescriptionKey }}
              </p>
            </div>
            <div class="col-md-4">
              <p>
                <i class="feather icon-clipboard mr-1 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("label.order_id") }}: </span>
                </strong>
                {{ model.orderId }}
              </p>
            </div>
            <div class="col-md-6">
              <p>
                <i class="feather icon-calendar mr-1 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("label.date_of_creation") }}: </span>
                </strong>
                {{ model.dateOutText }}
              </p>
            </div>
            <div class="col-md-6">
              <p>
                <i class="feather icon-user mr-1 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("label.generated_by") }}: </span>
                </strong>
                {{ model.generatedBy }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: {
        doctor: {
          fullName: "",
        },
        userBranchOffice: {
          user: {
            fullName: "",
          },
        },
      },
    };
  },
  methods: {
    reloadComponent(model) {
      this.model = model;
    },
  },
};
</script>
