<template>
  <div class="row">
    <div class="col-md-12 pb-0">
      <div class="card border-light">
        <div class="card-header card-header-detail">
          <i class="fa fa-building-o mr-2 text-fmedical-blue"></i>
          <strong>
            <span>
              {{ $t("picking.destination_branch") }}
            </span>
          </strong>
          {{ model.branchOfficeDestinationName }}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xs-12 col-md-4 pb-0">
              <p>
                <i class="ti-truck mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("label.shipping_id") }}: </span>
                </strong>
                {{ model.idShipment }}
              </p>
            </div>
            <div class="col-xs-12 col-md-4 pb-0">
              <p>
                <i class="ti-pin-alt mr-2 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("picking.order_id") }}</span>
                </strong>
                {{ model.orderId }}
              </p>
            </div>
            <div class="col-xs-12 col-md-4 pb-0">
              <p>
                <i class="feather icon-calendar mr-1 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("label.date_of_creation") }}:</span>
                </strong>
                {{ model.dateShipment }}
              </p>
            </div>
            <div class="col-xs-12 col-md-12">
              <p>
                <i class="feather icon-user mr-1 text-fmedical-blue"></i>
                <strong>
                  <span> {{ $t("label.generated_by") }}:</span>
                </strong>
                {{ model.userFullName ? model.userFullName.toUpperCase() : null }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      model: {
        branchOfficeDestination: {
          fullName: "",
        },
        userBranchOffice: {
          user: {
            fullName: "",
          },
        },
      },
    };
  },
  methods: {
    reloadComponent(model) {
      this.model = model;
    },
  },
};
</script>
