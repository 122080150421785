<template>
  <div class="p-2">
    <v-data-table
      :headers="header"
      :items="dataList.content"
      :search="search"
      fixed-header
      height="auto"
      :options.sync="options"
      :server-items-length="dataList.totalElements"
      :page.sync="page"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 text-right">
            <v-text-field
              v-model="search"
              v-on:keyup.enter="searchValue"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              :label="$t('table.search')"
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  ></i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div v-if="!medicalPrecription">
          <div
            v-if="
              (type == 1 && shipmentsCancel) ||
                (type == 0 && shipmentsCancelDispensing)
            "
          >
            <v-tooltip
              top
              v-if="item.statusShipment != 7 && item.statusShipment != 6"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="hand"
                  @click="cancellation(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i class="fa fa-ban text-danger ml-5"></i>
                </span>
              </template>
              <span> {{ $t("table.cancel") }} </span>
            </v-tooltip>

            <span v-if="item.statusShipment == 7 || item.statusShipment == 6">
              <i class="fa fa-ban ml-5" style="color: #e2e2e2"></i>
            </span>

            <v-icon
              small
              class="mr-2"
              color="#E2E2E2"
              v-if="item.statusShipment == 7 || item.statusShipment == 6"
            >
              mdi mdi-ban
            </v-icon>
          </div>
        </div>
        <div v-else>
          <div
            v-if="
              (type == 1 && shipmentsCancel) ||
                (type == 0 && shipmentsCancelDispensing)
            "
          >
            <v-tooltip
              top
              v-if="
                item.statusPrescription != 7 && item.statusPrescription != 3
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="hand"
                  @click="cancellation(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <i class="fa fa-ban text-danger ml-5"></i>
                </span>
              </template>
              <span> {{ $t("table.cancel") }} </span>
            </v-tooltip>

            <span
              v-if="
                item.statusPrescription == 7 || item.statusPrescription == 3
              "
            >
              <i class="fa fa-ban ml-5" style="color: #e2e2e2"></i>
            </span>

            <v-icon
              small
              class="mr-2"
              color="#E2E2E2"
              v-if="
                item.statusPrescription == 7 || item.statusPrescription == 3
              "
            >
              mdi mdi-ban
            </v-icon>
          </div>
        </div>
      </template>

      <template v-slot:[`item.statusShipment`]="{ item }">
        <ChipTableCommonPagination
          :status="item.statusShipment"
          :medicalPrecription="medicalPrecription"
        />
      </template>

      <template v-slot:[`item.statusPrescription`]="{ item }">
        <ChipTableCommonPagination
          :status="item.statusPrescription"
          :medicalPrecription="medicalPrecription"
        />
      </template>

      <template v-slot:[`item.prescriptionType`]="{ item }">
        {{ prescriptionType(item.prescriptionType) }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
       <span class="regular">
        <v-chip v-if="item.status == 1" color="green" dark class="m-1">
          Activo
        </v-chip>
        <v-chip v-if="item.status == 3" color="grey" dark class="m-1">
          Producto no conforme
        </v-chip>
        <v-chip v-if="item.status == 4" color="red" dark class="m-1">
          Cancelado
        </v-chip>
       </span>
      </template>
    </v-data-table>
    <ButtonPrintComponent />
  </div>
</template>

<script>
import ChipTableCommonPagination from "@/common/table/ChipTableCommonPagination";
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";

export default {
  props: {
    headers: {},
    type: null,
    shipmentsCancel: false,
    shipmentsCancelDispensing: false,
    pagesAtributes: Object,
    changeTable: Function,
  },
  data() {
    return {
      search: "",
      dataList: [],
      options: {},
      page: 1,
      header: this.headers,
      medicalPrecription: false,
      statusName: "",
    };
  },
  components: {
    ButtonPrintComponent,
    ChipTableCommonPagination,
  },
  watch: {
    search() {
      if (this.search == "") {
        this.searchValue();
      }
    },
    options: {
      handler() {
        this.pagination();
      },
      deep: true,
    },
  },
  methods: {
    /**
     * Table pagination. / Paginado de la tabla.
     * @method
     */
    async pagination() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.pagesAtributes.sortBy = sortBy[0];
      this.pagesAtributes.sortDesc = sortDesc[0];
      this.pagesAtributes.currentPage = page - 1;
      this.pagesAtributes.itemsPerPage = itemsPerPage;
      this.changeTable();
    },
    /**
     * Search input. / Input de búsqueda.
     * @method
     */
    async searchValue() {
      this.pagesAtributes.search = this.search;
      this.changeTable();
      this.page = 1;
    },

    /* General */
    reloadComponent(header, dataList, medicalPrecription) {
      //console.log(Object.keys(this.$refs.tableCancellation.footerProps));
      (this.header = header), (this.dataList = dataList);
      this.medicalPrecription = medicalPrecription;
    },
    reloadComponentHeader(header) {
      this.header = header;
    },
    reloadComponentSetList(dataList, header) {
      this.dataList.content = dataList;
      this.header = header;
    },
    reloadComponentClean() {
      this.dataList = [];
    },
    cancellation(item) {
      this.$emit("cancellation", item);
    },
    itemRowBackground: function(item, index) {
      return item.status == 7 ? "style-2" : "";
    },
    prescriptionType(id) {
      let typeName = "";
      switch (id) {
        case 1:
          typeName = "Recetas";
          break;

        case 2:
          typeName = "Intrahospitalaria";
          break;

        case 3:
          typeName = "SRS";
          break;

        default:
          typeName = "No definido";
      }
      return typeName;
    },
  },
};
</script>

<style scoped>
.style-2 {
  background-color: rgb(254, 187, 187);
}
</style>
